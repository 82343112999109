import React, { useState, useContext } from 'react';

import { LinkToPlants } from '../../pages/plants/_link';
import { LinkToTravel } from '../../pages/travel/_link';
import { LinkToArt } from '../../pages/art/_link';

import './nav.scss';

/**
 * Nav UI components
 */

export const NavToggle: React.FC = () => {
  const { isNavOpen, toggleNav } = useContext(NavContext) as INavContext;

  return (
    <button
      type="button"
      onClick={() => toggleNav()}
      aria-label={isNavOpen ? 'Close navigation' : 'Open navigation'}
      aria-pressed={isNavOpen}
      className={isNavOpen ? 'nav-toggle nav-toggle--open' : 'nav-toggle'}
    >
      <span className="nav-toggle__lines" role="presentation" />
    </button>
  );
};

export const Nav: React.FC = () => {
  const { isNavOpen } = useContext(NavContext) as INavContext;

  return (
    <nav
      className={isNavOpen ? 'nav' : 'nav nav--hidden'}
      aria-expanded={isNavOpen}
      aria-hidden={!isNavOpen}
    >
      <div className="nav__links">
        <a className="nav__link" href="/">
          Home
        </a>
        <LinkToTravel className="nav__link">Travel</LinkToTravel>
        <LinkToPlants className="nav__link">Plants</LinkToPlants>
        <LinkToArt className="nav__link">Art</LinkToArt>
      </div>
      <p>
        Want to reach out? You can contact me over{' '}
        <a href="mailto:hello@theskyandthesea.com">email</a>. 👋
      </p>
    </nav>
  );
};

/**
 * Nav state management and helpers
 */

export interface INavContext {
  isNavOpen: boolean;
  toggleNav(): void;
}
export const NavContext = React.createContext({});

export const NavProvider: React.FC = ({ children }) => {
  const [isNavOpen, setNavOpen] = useState(false);
  const toggleNav = () => setNavOpen(!isNavOpen);

  return <NavContext.Provider value={{ isNavOpen, toggleNav }}>{children}</NavContext.Provider>;
};

export const HideIfNavOpen: React.FC = ({ children }) => {
  const { isNavOpen } = useContext(NavContext) as INavContext;

  const props = {
    className: isNavOpen ? 'hidden-by-nav hidden-by-nav--hidden' : 'hidden-by-nav',
    'aria-hidden': isNavOpen ? true : false,
  };
  return React.cloneElement(children as React.ReactElement, props);
};
