import React, { useContext, useEffect } from 'react';

import { LogoContext, ILogoContext } from '../../components/logo/logo';

import image from './thumbnail.jpg';

interface Props {
  className?: string;
}

export const LinkToTravel: React.FC<Props> = ({ children, ...props }) => {
  const { setImage, clearImage } = useContext(LogoContext) as ILogoContext;
  const setLogoImage = () => setImage('TRAVEL', image);
  const clearLogoImage = () => clearImage('TRAVEL');

  return (
    <a
      {...props}
      href="/travel"
      onMouseOver={setLogoImage}
      onFocus={setLogoImage}
      onMouseLeave={clearLogoImage}
      onBlur={clearLogoImage}
    >
      {children}
    </a>
  );
};
