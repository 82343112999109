import React from 'react';

import { DarkModeSwitcher } from '../dark_mode_switcher/switch';
import { Nav, NavToggle, NavProvider, HideIfNavOpen } from '../nav/nav';
import { Logo, LogoProvider } from '../logo/logo';

import '../../styles/styles.scss';
import './layout.scss';

interface Props {
  logo?: React.ReactElement;
  className?: string;
}
export const Layout: React.FC<Props> = ({ logo, className, children }) => {
  return (
    <div className={`container ${className}`}>
      <LogoProvider>
        <div className="container__logo">
          <Logo logo={logo} />
        </div>
        <div className="container__content">
          <NavProvider>
            <div className="container__controls">
              <DarkModeSwitcher />
              <NavToggle />
            </div>
            <Nav />
            <HideIfNavOpen>
              <main>{children}</main>
            </HideIfNavOpen>
          </NavProvider>
        </div>
      </LogoProvider>
    </div>
  );
};
