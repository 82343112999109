import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
  path?: string;
  ogImage?: string;
}

export const Head: React.FC<Props> = ({ children, title, description, path = '', ogImage }) => {
  const siteTitle = 'The Sky and the Sea';
  const siteUrl = 'https://www.theskyandthesea.com';

  const pageTitle = title ? `${title} | ${siteTitle}` : siteTitle;
  const pageDescription =
    description ||
    'The Sky and the Sea is the personal website of an individual known as Cee. She enjoys in a variety of mediums, such as games, art, animation, photography, and writing.';
  const pageUrl = `${siteUrl}${path}`;
  const pageImage = `${siteUrl}${ogImage || '/og-image.png'}`;

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>{pageTitle}</title>
      <link rel="icon" type="image/x-icon" href="/favicon.ico" sizes="16x16 32x32" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" sizes="any" />
      <link rel="canonical" href={pageUrl} />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Karma:wght@400;600&family=Overpass+Mono:wght@600&display=swap"
        rel="stylesheet"
      />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:type" content="site" />
      <meta property="og:image" content={pageImage} />
      <meta property="og:url" content={pageUrl} />
      <meta name="description" content={pageDescription} />
      {children}
    </Helmet>
  );
};
