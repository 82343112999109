import React, { useContext } from 'react';

import { LogoContext, ILogoContext } from '../../components/logo/logo';

import image from './thumbnail.jpg';

interface Props {
  className?: string;
}

export const LinkToArt: React.FC<Props> = ({ children, ...props }) => {
  const { setImage, clearImage } = useContext(LogoContext) as ILogoContext;
  const setLogoImage = () => setImage('ART', image);
  const clearLogoImage = () => clearImage('ART');

  return (
    <a
      {...props}
      href="/art"
      onMouseOver={setLogoImage}
      onFocus={setLogoImage}
      onMouseLeave={clearLogoImage}
      onBlur={clearLogoImage}
    >
      {children}
    </a>
  );
};
