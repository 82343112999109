import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

import './switch.scss';

const DARK_MODE = true;
const LIGHT_MODE = false;
const NO_MANUAL_MODE: null = null;

/**
 * 1. Check localstorage for a manual preference
 * 2. Fall back to CSS (which automatically & dynamically picks up OS light/dark mode) if not
 */
const getInitialMode = () => {
  if (typeof window === 'undefined') return NO_MANUAL_MODE; // Workaround for Gatsby build

  const savedPreference = window.localStorage.getItem('dark-mode');
  if (!savedPreference) return NO_MANUAL_MODE;

  return savedPreference === 'true' ? DARK_MODE : LIGHT_MODE;
};

export const DarkModeSwitcher: React.FC = () => {
  const [mode, setMode] = useState(getInitialMode());
  const switchMode = () => {
    setMode(!mode);
    window.localStorage.setItem('dark-mode', String(!mode));
  };

  const hasSetModeManually = mode !== null;
  const isDarkMode = mode === DARK_MODE;

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: hasSetModeManually ? (isDarkMode ? 'dark-mode' : 'light-mode') : '',
        }}
      />
      <label className="dark-mode-switcher">
        <span className="dark-mode-switcher__graphic" role="presentation" />
        <input
          type="checkbox"
          checked={mode}
          onChange={switchMode}
          aria-label={
            hasSetModeManually
              ? isDarkMode
                ? 'Dark mode is enabled. Press to toggle light mode.'
                : 'Light mode is enabled. Press to toggle dark mode.'
              : 'This site is currently using your OS-level light or dark mode. Press to manually toggle modes.'
          }
          className="dark-mode-switcher__input"
        />
      </label>
    </>
  );
};
