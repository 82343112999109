import React, { useContext } from 'react';

import { LogoContext, ILogoContext } from '../../components/logo/logo';

import image from './thumbnail.jpg';

interface Props {
  className?: string;
}

export const LinkToPlants: React.FC<Props> = ({ children, ...props }) => {
  const { setImage, clearImage } = useContext(LogoContext) as ILogoContext;
  const setLogoImage = () => setImage('PLANTS', image);
  const clearLogoImage = () => clearImage('PLANTS');

  return (
    <a
      {...props}
      href="/plants"
      onMouseOver={setLogoImage}
      onFocus={setLogoImage}
      onMouseLeave={clearLogoImage}
      onBlur={clearLogoImage}
    >
      {children}
    </a>
  );
};
