import React, { useState, useContext } from 'react';

import { Mask } from './mask';
import { DefaultLogo } from './default_logo';
import './logo.scss';

interface Props {
  logo?: React.ReactNode;
}
export const Logo: React.FC<Props> = ({ logo }) => {
  const { images } = useContext(LogoContext) as ILogoContext;

  return (
    <div className="logo" aria-hidden="true" role="presentation">
      {logo ? logo : <DefaultLogo />}

      {Object.values(images).map(({ src, isVisible }, i) => {
        const classes = isVisible ? 'logo__image' : 'logo__image logo__image--hidden';
        return <img src={src} className={classes} alt="" key={i} />;
      })}

      <Mask />
    </div>
  );
};

/**
 * Logo state management
 */

interface IImage {
  [key: string]: {
    src: string;
    isVisible: boolean;
  };
}
export interface ILogoContext {
  images: IImage;
  setImage(key: string, src: string): void;
  clearImage(key: string): void;
}
export const LogoContext = React.createContext({});

export const LogoProvider: React.FC = ({ children }) => {
  // We're using a map of images & visibility to allow us to smoothly
  // transition / fade between between multiple different images
  const [images, setImages] = useState({});

  const setImage = (key: string, src: string) => {
    setImages({ ...images, [key]: { src, isVisible: true } });
  };
  const clearImage = (key: string) => {
    const newMap = { ...images } as IImage;
    newMap[key].isVisible = false;
    setImages(newMap);
  };

  return (
    <LogoContext.Provider value={{ images, setImage, clearImage }}>{children}</LogoContext.Provider>
  );
};
